import type { TocLink } from '@nuxt/content'
import { throttleAndDebounce } from '../helpers/throttleAndDebounce'

// Helper function to compare arrays
const arraysEqual = (a: string[], b: string[]): boolean => {
  if (a.length !== b.length) return false
  return a.every((val, index) => val === b[index])
}

export function useScrollspy(links: TocLink[]) {
  const activeHeadings = ref<string[]>([])
  const sectionMap = ref<Map<string, { top: number; bottom: number; level: number }>>(new Map())

  const updateSectionMap = () => {
    const docsContent = document.querySelector('.docs-content') as HTMLElement
    if (!docsContent) return

    const headers = docsContent.querySelectorAll('h2, h3, h4, h5, h6')
    const headerHeight = document.querySelector('header')?.offsetHeight || 0

    let currentSection: { id: string; top: number; level: number } | null = null
    sectionMap.value.clear()

    headers.forEach((header, index) => {
      const id = header.id
      const level = parseInt(header.tagName[1])
      const top = header.getBoundingClientRect().top + window.pageYOffset - headerHeight

      if (currentSection) {
        sectionMap.value.set(currentSection.id, {
          top: currentSection.top,
          bottom: top,
          level: currentSection.level
        })
      }

      currentSection = { id, top, level }

      // If it's the last header, set the bottom to the end of the docs-content
      if (index === headers.length - 1) {
        const bottom = docsContent.offsetTop + docsContent.offsetHeight
        sectionMap.value.set(id, { top, bottom, level })
      }
    })
  }

  const determineActiveHeadings = () => {
    const scrollPosition = window.pageYOffset
    const headerHeight = document.querySelector('header')?.offsetHeight || 0
    const visiblePosition = scrollPosition + headerHeight

    let currentActiveSection: string | null = null
    let lowestLevel = Infinity
    let firstVisibleSection: string | null = null

    for (const [id, section] of sectionMap.value.entries()) {
      if (visiblePosition >= section.top && visiblePosition < section.bottom) {
        if (section.level < lowestLevel) {
          currentActiveSection = id
          lowestLevel = section.level
        }
      }
      // Keep track of the first visible section
      if (firstVisibleSection === null && section.bottom > visiblePosition) {
        firstVisibleSection = id
      }
    }

    // If no active section is found, use the first visible section
    if (!currentActiveSection && firstVisibleSection) {
      currentActiveSection = firstVisibleSection
    }

    if (!currentActiveSection) {
      activeHeadings.value = []
      return
    }

    // Build the active path from the current heading up to its ancestors
    const buildActivePath = (id: string, links: TocLink[]): string[] => {
      for (const link of links) {
        if (link.id === id) {
          return [link.id]
        }
        if (link.children) {
          const childPath = buildActivePath(id, link.children)
          if (childPath.length) {
            return [link.id, ...childPath]
          }
        }
      }
      return []
    }

    const newActiveHeadings = buildActivePath(currentActiveSection, links)
    if (!arraysEqual(activeHeadings.value, newActiveHeadings)) {
      activeHeadings.value = newActiveHeadings
    }
  }

  // Throttled scroll event handler
  const onScroll = throttleAndDebounce(() => {
    determineActiveHeadings()
  }, 100)

  // Lifecycle hooks
  onMounted(() => {
    window.addEventListener('scroll', onScroll)
    updateSectionMap()
    determineActiveHeadings() // Initial determination
  })

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', onScroll)
  })

  // Watch for changes in the links
  watch(
    () => links,
    () => {
      updateSectionMap()
      determineActiveHeadings()
    },
    { deep: true }
  )

  return {
    activeHeadings,
    updateSectionMap
  }
}
